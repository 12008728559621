import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

// packages
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import OtpInput from 'react-otp-input'

// colors
import { THEME_COLORS } from '../../../ThemeConfig'

// media
import screenBG from '../../../assets/images/login/login-bg1.jpg'
import logo from '../../../assets/icons/login/login-screen-icon.svg'
import rightArrow from '../../../assets/icons/chevron-right.svg'
import headerLogo from '../../../assets/icons/login/header-logo.svg'
import dropdownArrow from '../../../assets/icons/dropdown-arrow.svg'
import emergencyIcon from '../../../assets/icons/emergency-call.svg'
import alertCircle from '../../../assets/icons/alert-circle.svg'
import importantIcon from '../../../assets/images/important-icon.svg'
import ContactPic from '../../../assets/images/contact-you.svg'
import leftArrow from '../../../assets/images/left-arrow.svg'

// store
import { Context } from '../../../contexts/Store'

// modal
import GenderModal from '../../includes/modals/GenderModal'

// Lottie
import Lottie from 'lottie-react'
import LoaderLottie from '../../../assets/lottie/LoaderLottie.json'

// axios Configuration
import { PatientConfig } from '../../../axiosConfig'
import PreviousButton from '../../includes/buttons/PreviousButton'
import { Popover } from '@mui/material'

export default function Login() {
  const { dispatch, state } = useContext(Context)
  const navigate = useNavigate()
  const location = useLocation()
  const [otp, setOTP] = useState('')
  const [isTimerRunning, setIsTimerRunning] = useState(true)
  const [resendOTPTime, setResendOTPTime] = useState(15)
  const [isResendAttemptOver, setIsResendAttemptOver] = useState(false)
  const isVerified = state.user_details.is_verified
  // const [mobileNumber, setMobileNumber] = useState(
  //     state.user_details.phone_number || ""
  // );
  const [mobileNumber, setMobileNumber] = useState('')
  const [age, setAge] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [gender, setGender] = useState('')
  const [isOpen, setOpen] = useState(false)
  const [isInputFocused, setInputFocus] = useState(false)
  const [isNameInputFocused, setIsNameInputFocused] = useState(false)
  const [isMobileInputFocused, setIsMobileInputFocused] = useState(false)
  const [isEmailInputFocused, setisEmailInputFocused] = useState(false)
  const [isAgeInputFocused, setisAgeInputFocused] = useState(false)
  const [isGenderInputFocused, setIsGenderInputFocused] = useState(false)
  const [isShowOtp, setIsShowOtp] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoginLoading, setIsLoginLoading] = useState(false)
  const [isOtpLoading, setIsOtpLoading] = useState(false)

  // const [attemptCount, setAttemptCount] = useState(0);
  const [error, setError] = useState({
    name: '',
    age: '',
    email: '',
    mobileNumber: '',
    otp: '',
    gender: '',
    loginEmail: '',
  })

  const pathname = location.pathname
  const page = pathname.split('/').filter((s) => s)[
    pathname.split('/').filter((s) => s).length - 1
  ]
  const selectorRef = useRef(null)
  const selectorDropDownRef = useRef(null)

  // Gender popover onClick
  const [genderPopoverAnchorEl, setGenderPopoverAnchorEl] = useState(null)
  const genderPopoverButtonRef = useRef(null)
  const handleClickGenderPopover = () => {
    setGenderPopoverAnchorEl(genderPopoverButtonRef.current)
    setOpen(true)
    setIsGenderInputFocused(true)
  }
  const handleCloseGenderPopover = () => {
    setGenderPopoverAnchorEl(null)
    setOpen(false)
    setIsGenderInputFocused(false)
  }
  const openGenderPopoverFilter = Boolean(genderPopoverAnchorEl)
  const genderPopoverStyle = {
    position: 'absolute',
    top: -145,
    left: 0,
    borderRadius: '12px',
  }

  const handleLoginSubmit = (event) => {
    // if (mobileNumber === "" || mobileNumber.length < 10) {
    //     setError((prevError) => ({
    //         ...prevError,
    //         mobileNumber:
    //             mobileNumber === ""
    //                 ? "Enter a valid mobile number"
    //                 : "Mobile number must be 10 digits.",
    //     }));
    // } else {
    // loginData.append("country", "IN");
    // loginData.append("phone", mobileNumber);
    event.preventDefault()

    setIsLoginLoading(true)
    const loginData = new FormData()
    loginData.append('email', email)

    PatientConfig.post('api/v1/patients/authentication/login/', loginData, {})
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          dispatch({
            type: 'UPDATE_USER',
            user_details: {
              email: email,
            },
          })
          setIsLoginLoading(false)
          navigate('/auth/enterotp')
        } else {
          setError((prevError) => ({
            ...prevError,
            loginEmail: data.data.message,
          }))
          setIsLoginLoading(false)
        }
      })
      .catch((errors) => {
        // reseting errors before next try
        setIsLoginLoading(false)
        // setError((prevError) => ({
        //     ...prevError,
        //     mobileNumber: "",
        // }));

        const message = errors.response.data.data.message

        setError((prevError) => ({
          ...prevError,
          loginEmail: message,
        }))
      })
    // }

    // alert("Wait, api connection pending");
    // prevent the form from submitting
    // handle form submission here
  }

  function handleSignUpSubmit(event) {
    event.preventDefault()
    const formData = new FormData()
    formData.append('full_name', name)
    formData.append('country', 'IN')
    formData.append('phone', mobileNumber)
    formData.append('email', email)
    formData.append('age', age)
    formData.append('gender', gender)

    if (name == '') {
      setError((prevError) => ({
        ...prevError,
        name: 'Please fill out this field',
      }))
    } else if (mobileNumber == '') {
      setError((prevError) => ({
        ...prevError,
        mobileNumber: 'Please fill out this field',
      }))
    } else if (email == '') {
      setError((prevError) => ({
        ...prevError,
        email: 'Email ID cannot be blank',
      }))
    } else if (age == '') {
      setError((prevError) => ({
        ...prevError,
        age: 'Age cannot be blank',
      }))
    } else if (gender == '') {
      setError((prevError) => ({
        ...prevError,
        gender: 'Please select one',
      }))
    } else {
      PatientConfig.post('api/v1/patients/authentication/signup/', formData, {})
        .then((response) => {
          // reseting errors before next try
          setError((prevError) => ({
            ...prevError,
            otp: '',
          }))

          const { StatusCode, data } = response.data
          const message = response.data.data.message
          if (StatusCode === 6000) {
            // dispatch({
            //     type: "UPDATE_USER",
            //     user_details: {
            //         user_name: name,
            //         user_age: age,
            //         gender: gender,
            //         email: email,
            //         phone_number: mobileNumber,
            //     },
            // });
            // state.user_details.is_new_user = true;
            navigate('/auth/enterotp')
          } else {
            // Handle other StatusCode values if needed
            // console.error("Unexpected StatusCode:", StatusCode);
          }
        })
        .catch((errors) => {
          // reseting errors before next try
          setError((prevError) => ({
            ...prevError,
            name: '',
          }))
          setError((prevError) => ({
            ...prevError,
            mobileNumber: '',
          }))
          setError((prevError) => ({
            ...prevError,
            email: '',
          }))

          const message = errors.response?.data?.data?.message

          if (message) {
            // Handle specific error messages from the server
            if (message.includes('full_name')) {
              setError((prevError) => ({
                ...prevError,
                name: 'Should be at least 5 characters',
              }))
            } else if (message.includes('phone')) {
              setError((prevError) => ({
                ...prevError,
                mobileNumber: 'Phone number already exists',
              }))
            } else if (message.includes('email')) {
              setError((prevError) => ({
                ...prevError,
                email: 'Email ID already exists',
              }))
            } else {
              // console.error("Unexpected error message:", message);
            }
          } else {
            // console.error("Unexpected error:", errors);
          }
        })
    }
    // prevent the form from submitting
    // handle form submission here
  }

  function handleOTPsubmit(event) {
    // event.preventDefault();
    if (otp == '' || otp.length < 4) {
      setError((prevError) => ({
        ...prevError,
        otp: 'Enter a valid otp',
      }))
    } else {
      setIsOtpLoading(true)
      const otpData = new FormData()
      // otpData.append("country", "IN");
      otpData.append('email', email)
      otpData.append('otp', otp)

      PatientConfig.post(
        'api/v1/patients/authentication/verify-otp/',
        otpData,
        {},
      )
        .then((response) => {
          const { StatusCode, data } = response.data
          if (StatusCode === 6000) {
            dispatch({
              type: 'UPDATE_USER',
              user_details: {
                user_id: data.user_id,
                user_name: data.full_name,
                user_age: age,
                gender: gender,
                email: data.email,
                phone_number: data.phone,
                access_token: response.data?.data?.access_token,
                refresh_token: response.data?.data?.refresh_token,
              },
            })
            setTimeout(() => {
              navigate('/auth/getstarted')
            }, 500)
            setIsOtpLoading(false)
          } else {
            setIsOtpLoading(false)
            setError((prevError) => ({
              ...prevError,
              otp: 'OTP validation failed',
            }))
          }
        })

        .catch((errors) => {
          // reseting errors before next try
          // setError((prevError) => ({
          //     ...prevError,
          //     otp: "",
          // }));

          const message = errors.response.data.data.message
          setError((prevError) => ({
            ...prevError,
            otp: message,
          }))
          setIsOtpLoading(false)

          // if (message === "otp - A valid integer is required.") {
          //     setError((prevError) => ({
          //         ...prevError,
          //         otp: "Please fill out this field",
          //     }));
          // } else if (message === "Invalid OTP") {
          //     setError((prevError) => ({
          //         ...prevError,
          //         otp: "Invalid OTP",
          //     }));
          // } else {
          //     setError((prevError) => ({
          //         ...prevError,
          //         otp: message,
          //     }));
          // }
        })
    }

    // prevent the form from submitting
    // handle form submission here
  }

  // const handleOTPChange = (event) => {
  //     const value = event.target.value;
  //     const index = parseInt(event.target.name);
  //     let newOtp = otp;
  //     newOtp =
  //         newOtp.substring(0, index) + value + newOtp.substring(index + 1);
  //     setOTP(newOtp);
  //     if (index < 3 && value !== "") {
  //         // Move focus to the next input field
  //         refs[index + 1].current.focus();
  //     }
  // };
  const handleResendOTP = () => {
    const resendOtpData = new FormData()
    // resendOtpData.append("country", "IN");
    resendOtpData.append('email', email)

    PatientConfig.post(
      'api/v1/patients/authentication/resend-otp/',
      resendOtpData,
      {},
    )
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          if (data.remaining_attempts > 0) {
            setIsTimerRunning(true)
            setResendOTPTime(15)
          } else {
            setIsResendAttemptOver(true)
            setIsTimerRunning(true)
            setResendOTPTime(0)
            // setTimeout(() => {
            //     navigate("/auth/login");
            // }, resendOTPTime);
          }
        } else if (StatusCode === 6001) {
          setError((prevError) => ({
            ...prevError,
            otp: data.message,
          }))
        }
      })
      .catch((error) => {
        // alert(error.response.data.data.message);
        setError((prevError) => ({
          ...prevError,
          otp: error?.response?.data?.data?.message,
        }))
      })
  }

  const handleBack = () => {
    navigate(-1)
  }

  const handleLogin = () => {
    const user_details = {
      is_verified: true,
      is_login: true,
    }
    dispatch({
      type: 'UPDATE_USER',
      user_details: {
        is_verified: true,
        is_login: true,
      },
    })
    navigate('/')
  }
  const handleSkip = () => {
    const user_details = {
      is_login: true,
      is_new_user: true,
    }
    dispatch({
      type: 'UPDATE_USER',
      user_details: {
        is_login: true,
        is_new_user: true,
      },
    })
    navigate('/')
  }

  const handleNameInputChange = (e) => {
    setError((prev) => ({
      ...prev,
      name: '',
    }))
    const value = e.target.value

    if (/^[a-zA-Z ]*$/.test(value) || value === '') {
      setName(value)
    }
  }

  const handleMobileInputChange = (e) => {
    setError((prev) => ({
      ...prev,
      mobileNumber: '',
    }))
    let value = e.target.value
    value = value.slice(0, 10) // Trim to 10 digits
    if (/^\d*$/.test(value) || value === '') {
      setMobileNumber(value)
    }
  }

  const validateEmail = (email) => {
    // Regular expression for email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
  }

  // useEffect(() => {
  //     if (isVerified === true) {
  //         navigate("/");
  //     }
  // }, [isVerified]);

  useEffect(() => {
    let intervalId
    if (isTimerRunning && resendOTPTime > 0) {
      intervalId = setInterval(() => {
        setResendOTPTime((prevTime) => prevTime - 1)
      }, 1000)
    } else if (resendOTPTime === 0) {
      setIsTimerRunning(false)
      clearInterval(intervalId)
    }
    return () => clearInterval(intervalId)
  }, [isTimerRunning, resendOTPTime])

  useEffect(() => {
    if (otp) {
      if (page !== 'enterotp') {
        setOTP('')
      }
    }
    if (isResendAttemptOver) {
      if (page !== 'enterotp') {
        setIsResendAttemptOver(false)
      }
    }
  }, [otp, page, isResendAttemptOver])

  useEffect(() => {
    if (page == 'enterotp') {
      if (!email) {
        navigate('/auth/login')
      }
    }
    if (page !== 'enterotp') {
      if (error.otp !== '') {
        setError((prev) => ({
          ...prev,
          otp: '',
        }))
      }
    }
    // if (page == "getstarted") {
    //     if (!email) {
    //         handleLogin();
    //     }
    // }
  }, [page, email, error])

  return (
    <MainContainer>
      <BGContainer>
        <Header>
          <HeaderLogo src={headerLogo} alt="icon" />
        </Header>
        <LoginBox>
          <LoginWhiteBox className={page == 'login' && 'login'}>
            {page !== 'emergency' && page !== 'emergency-contact' && (
              <LoginIcon src={logo} alt="icon" />
            )}
            {page == 'emergency' && (
              <>
                <EmergencyContainer>
                  <EmergencyTitle>Emergency Session</EmergencyTitle>
                  <Subtitle>Immediate support when you need it most.</Subtitle>
                  <SmallHead>Features</SmallHead>
                  <Features>Priority access</Features>
                  <Features>Quick connection</Features>
                  <Features>Confidential and secure</Features>
                  <SmallHead>Pricing</SmallHead>
                  <Features>20% extra charge</Features>
                  <TagLine>
                    {' '}
                    <ImpContainer>
                      <Imp src={importantIcon} alt="important icon" />
                    </ImpContainer>
                    <Notify>
                      For urgent situations only. Regular session available
                      during business hours.
                    </Notify>
                  </TagLine>
                  <CallButton
                    onClick={() => {
                      navigate('/auth/emergency-contact')
                    }}
                  >
                    Call Me Now
                  </CallButton>
                </EmergencyContainer>
              </>
            )}
            {page == 'emergency-contact' && (
              <>
                <ContactImageContainer>
                  <ContactImage src={ContactPic} alt="a person is calling" />
                </ContactImageContainer>
                <ReachOut>We will contact you in a moment...</ReachOut>
                <GotButton
                  onClick={() => {
                    navigate('/')
                  }}
                >
                  Got it
                </GotButton>
              </>
            )}

            {(page == 'login' || page == 'signup') && (
              <>
                <SkipButton onClick={() => handleSkip()}>
                  {' '}
                  Skip For Now
                </SkipButton>
                <TitleText>
                  {page == 'login' ? `Login` : 'Signup'} to mindmitra
                </TitleText>
                <SubTitleText>
                  Expert support for a happier, healthier you.
                </SubTitleText>
              </>
            )}
            {(page == 'enterotp' || page == '') && (
              <>
                <TitleText>Verify Email OTP</TitleText>
                <SubTitleText>Enter the OTP shared to your email</SubTitleText>
              </>
            )}
            {page == 'getstarted' && (
              <>
                <TitleText>Welcome to Mindmitra</TitleText>
                <SubTitleText>
                  Your journey to well-being starts now
                </SubTitleText>
              </>
            )}
            {page == 'login' && (
              <form
                noValidate
                onSubmit={(event) => {
                  event.preventDefault()
                  if (email == '') {
                    setError((prev) => ({
                      ...prev,
                      loginEmail: 'Please enter a valid email address.',
                    }))
                  } else if (!validateEmail(email)) {
                    setError((prev) => ({
                      ...prev,
                      loginEmail: 'Please enter a valid email address.',
                    }))
                  } else if (!isLoginLoading) {
                    handleLoginSubmit(event)
                  }
                }}
                style={{ width: '100%', marginTop: '30px' }}
              >
                <InputFieldContainer className={error.loginEmail && 'error'}>
                  <InputWrapper>
                    <InputLabel
                      htmlFor="email"
                      className={email ? 'active' : ''}
                    >
                      Email
                    </InputLabel>
                    <InputField
                      // required
                      type="email"
                      autoFocus
                      id="email"
                      value={email}
                      onChange={(e) => {
                        setError((prev) => ({
                          ...prev,
                          loginEmail: '',
                        }))
                        setEmail(e.target.value.toLowerCase())
                      }}
                      onFocus={() => {
                        setisEmailInputFocused(true)
                        setError((prev) => ({
                          ...prev,
                          loginEmail: '',
                        }))
                      }}
                      onBlur={() => {
                        setisEmailInputFocused(false)
                        // // Custom validation: Check if '@' is present before showing error
                        if (email.length > 0 && !email.includes('@')) {
                          setError((prev) => ({
                            ...prev,
                            loginEmail: 'Please enter a valid email address.',
                          }))
                        } else if (email && !validateEmail(email)) {
                          setError((prev) => ({
                            ...prev,
                            loginEmail: 'Please enter a valid email address.',
                          }))
                        } else {
                          setError((prev) => ({
                            ...prev,
                            loginEmail: '',
                          }))
                        }
                      }}
                    />
                    <Alert
                      className={error.loginEmail ? 'show' : ''}
                      src={alertCircle}
                      alt="alert-circle"
                    />
                  </InputWrapper>
                </InputFieldContainer>
                {error.loginEmail && (
                  <ErrorMessage>{error.loginEmail}</ErrorMessage>
                )}

                {/* end of email field */}

                {!isLoginLoading ? (
                  <LoginButton type="submit">Login</LoginButton>
                ) : (
                  <LoginButton
                    style={{
                      position: 'relative',
                      opacity: '0.9',
                      cursor: 'not-allowed',
                    }}
                    // type="submit"
                  >
                    <Lottie
                      animationData={LoaderLottie}
                      style={{
                        width: 190,
                        margin: 'auto',
                        // color: "#fff",
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                      }}
                    />
                  </LoginButton>
                )}
              </form>
            )}

            {page == 'signup' && (
              <>
                <form
                  onSubmit={handleSignUpSubmit}
                  style={{ width: '100%', marginTop: '0px' }}
                  noValidate
                >
                  <InputFieldContainer
                    className={`${isNameInputFocused ? 'input-focused' : ''} ${
                      name && !error.name ? 'active' : error.name ? 'error' : ''
                    }`}
                  >
                    <InputWrapper>
                      <InputLabel
                        htmlFor="name"
                        className={
                          isNameInputFocused || (name && !error.name)
                            ? 'active'
                            : ''
                        }
                      >
                        Full Name
                      </InputLabel>
                      <InputField
                        // required
                        onFocus={() => setIsNameInputFocused(true)}
                        onBlur={() => setIsNameInputFocused(false)}
                        type="text"
                        id="name"
                        value={name}
                        onChange={handleNameInputChange}
                      />
                      <Alert
                        className={error.name ? 'show' : ''}
                        src={alertCircle}
                        alt="alert-circle"
                      />
                    </InputWrapper>
                  </InputFieldContainer>
                  {error.name && <ErrorMessage>{error.name}</ErrorMessage>}
                  <InputFieldContainer
                    className={`${isMobileInputFocused ? 'input-focused' : ''} 
                                            ${
                                              mobileNumber &&
                                              !error.mobileNumber
                                                ? 'active'
                                                : ''
                                            } ${
                      error.mobileNumber ? 'error' : ''
                    }`}
                  >
                    <InputLabel
                      htmlFor="mobile-number"
                      className={mobileNumber ? 'active' : ''}
                    >
                      Mobile Number
                    </InputLabel>
                    <InputWrapper>
                      {mobileNumber || isMobileInputFocused ? (
                        <PreText
                        // style={{
                        //     marginTop: "7.5px",
                        // }}
                        >
                          +91
                        </PreText>
                      ) : null}

                      <InputField
                        // required
                        id="mobile-number"
                        type="tel"
                        maxLength="10"
                        minLength="10"
                        value={mobileNumber}
                        onChange={handleMobileInputChange}
                        pattern="[0-9]*"
                        autocomplete="off"
                        onFocus={() => setIsMobileInputFocused(true)}
                        onBlur={() => setIsMobileInputFocused(false)}
                      />
                      <Alert
                        className={error.mobileNumber ? 'show' : ''}
                        src={alertCircle}
                        alt="alert-circle"
                      />
                      <Alert
                        className={error.mobileNumber ? 'show' : ''}
                        src={alertCircle}
                        alt="alert-circle"
                      />
                    </InputWrapper>
                  </InputFieldContainer>
                  {error.mobileNumber && (
                    <ErrorMessage>{error.mobileNumber}</ErrorMessage>
                  )}
                  <InputFieldContainer
                    className={`${isEmailInputFocused ? 'input-focused' : ''} 
                                            ${
                                              email && !error.email
                                                ? 'active'
                                                : ''
                                            } ${error.email ? 'error' : ''}`}
                  >
                    <InputWrapper>
                      <InputLabel
                        htmlFor="email"
                        className={email ? 'active' : ''}
                      >
                        Email
                      </InputLabel>
                      <InputField
                        // required
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => {
                          setError((prev) => ({
                            ...prev,
                            email: '',
                          }))
                          setEmail(e.target.value)
                          // Custom validation: Check if '@' is present before showing error
                          if (!e.target.value.includes('@')) {
                            setError((prev) => ({
                              ...prev,
                              email: 'Please enter a valid email address.',
                            }))
                          }
                        }}
                        onFocus={() => setisEmailInputFocused(true)}
                        onBlur={() => setisEmailInputFocused(false)}
                      />
                      <Alert
                        className={error.email ? 'show' : ''}
                        src={alertCircle}
                        alt="alert-circle"
                      />
                    </InputWrapper>
                  </InputFieldContainer>
                  {error.email && <ErrorMessage>{error.email}</ErrorMessage>}
                  <InputWrapper>
                    <InputFieldContainer
                      style={{ width: '48%' }}
                      className={`${isAgeInputFocused ? 'input-focused' : ''} 
                                             ${
                                               age && !error.age ? 'active' : ''
                                             } ${error.age ? 'error' : ''}`}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <InputLabel
                          htmlFor="age"
                          className={age ? 'active' : ''}
                        >
                          Age
                        </InputLabel>

                        <InputField
                          // required
                          type="number"
                          id="age"
                          value={age}
                          onChange={(e) => {
                            setError((prev) => ({
                              ...prev,
                              age: '',
                            }))
                            setAge(
                              e.target.value.length < 3 ? e.target.value : age,
                            )
                          }}
                          onFocus={() => setisAgeInputFocused(true)}
                          onBlur={() => setisAgeInputFocused(false)}
                        />
                        <Alert
                          className={error.age ? 'show' : ''}
                          src={alertCircle}
                          alt="alert-circle"
                        />
                      </div>
                    </InputFieldContainer>
                    <InputFieldContainer
                      style={{
                        width: '48%',
                        height: '56px',
                      }}
                      ref={genderPopoverButtonRef}
                      onClick={handleClickGenderPopover}
                      // onClick={() => {
                      //     setOpen(!isOpen);
                      //     setIsGenderInputFocused(
                      //         !isGenderInputFocused
                      //     );
                      // }}
                      className={`
                                                ${
                                                  isGenderInputFocused
                                                    ? 'input-focused'
                                                    : ''
                                                }
                                                ${
                                                  (isOpen || gender) &&
                                                  !error.gender
                                                    ? 'active'
                                                    : error.gender
                                                    ? 'error'
                                                    : ''
                                                }
                                            `}
                    >
                      <InputWrapper ref={selectorRef}>
                        <InputLabel
                          className={isOpen || gender ? 'active' : ''}
                        >
                          Gender
                        </InputLabel>
                        <GenderField>
                          {gender === '1'
                            ? 'Male'
                            : gender === '2'
                            ? 'Female'
                            : gender === '3'
                            ? 'Others'
                            : ''}
                        </GenderField>
                        <Arrow
                          src={dropdownArrow}
                          alt="dropdown-arrow"
                          isOpen={openGenderPopoverFilter ? true : false}
                        />
                      </InputWrapper>
                    </InputFieldContainer>
                    <Popover
                      style={genderPopoverStyle}
                      open={openGenderPopoverFilter}
                      anchorEl={genderPopoverAnchorEl}
                      onClose={handleCloseGenderPopover}
                      sx={{
                        '&& .MuiPopover-paper': {
                          // border: "1px solid #e7e7e7",
                          boxShadow: '10px 12px 48px 0px rgba(0, 0, 0, 0.25)',
                          // boxShadow:
                          //     "8px 16px 44px rgba(0, 0, 0, 0.05)",
                          borderRadius: '12px',
                        },
                      }}
                    >
                      <GenderModal
                        gender={gender}
                        setError={setError}
                        setGender={setGender}
                        onClose={() => {
                          setOpen(false)
                          handleCloseGenderPopover()
                        }}
                        setIsGenderInputFocused={setIsGenderInputFocused}
                        genderPopoverButtonRef={genderPopoverButtonRef}
                      />
                    </Popover>
                  </InputWrapper>
                  {/* {console.log(error, "sdsd")} */}
                  {error.age && (
                    <ErrorMessage className="ageError">
                      {error.age}
                    </ErrorMessage>
                  )}
                  {error.gender && (
                    <ErrorMessage className="genderError">
                      {error.gender}
                    </ErrorMessage>
                  )}
                  <Caption>
                    By signing up, you agree to our{' '}
                    <Terms to="/terms-n-conditions">terms</Terms> and{' '}
                    <Policy to="/privacy-policies">privacy policy.</Policy>
                  </Caption>
                  <LoginButton className="signup" type="submit">
                    Continue
                  </LoginButton>
                </form>
              </>
            )}
            {page == 'enterotp' && (
              <form
                onSubmit={(event) => {
                  event.preventDefault()
                  if (!isOtpLoading) {
                    handleOTPsubmit(event)
                  }
                }}
                style={{ width: '100%', marginTop: '30px' }}
              >
                <OtpInput
                  value={otp}
                  onChange={(e) => {
                    setOTP(e)
                    setError((prev) => ({
                      ...prev,
                      otp: '',
                    }))
                  }}
                  numInputs={4}
                  inputType="tel"
                  renderInput={(props) => <input {...props} />}
                  containerStyle="container"
                  inputStyle={error.otp ? 'login-otp' : 'login-otp-input'}
                />
                {error.otp && (
                  <ErrorMessage className="otpError">{error.otp}</ErrorMessage>
                )}
                <Buttons>
                  <BackButtonContainer onClick={handleBack}>
                    <ArrowImageContainer>
                      <ArrowImage src={leftArrow} alt="arrow-image" />
                    </ArrowImageContainer>
                    <Previous>Back</Previous>
                  </BackButtonContainer>
                  {!isOtpLoading ? (
                    <LoginButton className="otp" type="submit">
                      Verify
                    </LoginButton>
                  ) : (
                    <LoginButton
                      className="otp"
                      style={{
                        position: 'relative',
                        opacity: '0.9',
                        cursor: 'not-allowed',
                      }}
                      // type="submit"
                    >
                      <Lottie
                        animationData={LoaderLottie}
                        style={{
                          width: 190,
                          margin: 'auto',
                          // color: "#fff",
                          position: 'absolute',
                          top: 0,
                          bottom: 0,
                          right: 0,
                          left: 0,
                        }}
                      />
                    </LoginButton>
                  )}
                </Buttons>
              </form>
            )}
            {page == 'getstarted' && (
              <>
                <LoginButton
                  style={{ marginTop: '30px' }}
                  onClick={(e) => {
                    e.preventDefault()
                    handleLogin()
                  }}
                >
                  {/* Get Started&nbsp;&#x3e; */}
                  Get Started
                  <RightArrow src={rightArrow} alt="icon" />
                </LoginButton>
              </>
            )}
            {/* {(page == "login" || page == "signup") && (
              <OrLine>
                <Hr></Hr>
                <OrText>or</OrText>
                <Hr></Hr>
              </OrLine>
            )} */}
            {/* {(page == "login" || page == "signup") && (
              <>
                <ContinueButton>
                  <ContinueButtonIcon src={googleLogo} alt="icon" />
                  <ContinueButtonText>Continue with Google</ContinueButtonText>
                </ContinueButton>
                <ContinueButton>
                  <ContinueButtonIcon src={facebookLogo} alt="icon" />
                  <ContinueButtonText>
                    Continue with Facebook
                  </ContinueButtonText>
                </ContinueButton>
              </>
            )} */}
            {(page == 'login' || page == 'signup') && (
              <SwitchLine>
                <GreyText>
                  {page == 'login'
                    ? `Don’t have an Account`
                    : 'Already have an Account'}{' '}
                </GreyText>
                <BlueText
                  onClick={() => {
                    if (page == 'login') {
                      setError({
                        name: '',
                        age: '',
                        email: '',
                        mobileNumber: '',
                        otp: '',
                      })
                      navigate('/auth/signup/')
                    } else if (page == 'signup') {
                      navigate('/auth/login/')
                    }
                  }}
                >
                  {page == 'login'
                    ? `Signup!`
                    : page == 'signup'
                    ? `Login`
                    : null}
                </BlueText>
              </SwitchLine>
            )}
            {page == 'enterotp' && (
              <SwitchLine>
                <GreyText>
                  {!isResendAttemptOver
                    ? `Haven’t received an OTP`
                    : `Haven’t received an OTP, resend attempts exceeded`}{' '}
                </GreyText>
                {!isTimerRunning ? (
                  isResendAttemptOver ? (
                    <BlueText
                      onClick={() => {
                        navigate('/auth/login')
                      }}
                    >
                      Go back
                    </BlueText>
                  ) : (
                    <BlueText
                      onClick={() => {
                        handleResendOTP()
                      }}
                    >
                      Resend
                    </BlueText>
                  )
                ) : (
                  !isResendAttemptOver && (
                    <BlueText
                      // onClick={() => {}}
                      style={{ cursor: 'not-allowed' }}
                    >
                      Resend in {resendOTPTime}s
                    </BlueText>
                  )
                )}
              </SwitchLine>
            )}
          </LoginWhiteBox>
          {(page == 'login' || page == 'signup') && (
            <ExpertText>
              <a
                href="https://expert.mymindmitra.com/auth/register-page"
                target="_blank"
              >
                Become a mindmitra expert
              </a>
            </ExpertText>
          )}
        </LoginBox>
      </BGContainer>
    </MainContainer>
  )
}
const MainContainer = styled.div`
  background: ${THEME_COLORS.white};
  padding: 0 25px;
  position: relative;
  ::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 980px) {
    padding: 0 15px;
  }
  @media screen and (max-width: 769px) {
    padding: 0 10px;
  }
  @media screen and (max-width: 420px) {
    padding: 0;
  }
`
const BGContainer = styled.div`
  width: 100%;
  height: 100vh !important;
  background-image: url(${screenBG});
  background-repeat: repeat-y;
  background-size: cover;
  animation: scroll 30s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* filter: blur(5px); */
  &::before {
    content: '';
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(
      0,
      0,
      0,
      0.6
    ); /* change the last value for the opacity */
  }
  @media screen and (max-width: 420px) {
    background-image: none;
    display: block;
    &::before {
      content: none;
    }
  }
`
const SkipButton = styled.div`
  font-size: 14px;
  color: ${THEME_COLORS.chips_green_on_container};
  font-family: 'DM_sans_medium';
  text-align: right;
  display: block;
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 25px;
`

const LoginBox = styled.div`
  position: relative;
  z-index: 2;
  background: ${THEME_COLORS.primary};
  box-shadow: 9px 17px 102px rgba(0, 0, 0, 0.34);
  border-radius: 24px;
  max-height: 95vh;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 420px) {
    z-index: 0;
    box-shadow: none;
    max-height: auto;
    border-radius: 0 0 24px 24px;
    width: 100%;
    margin-top: 60px;
  }
`
const LoginWhiteBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${THEME_COLORS.white};
  border-radius: 24px;
  padding: 40px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 420px;
  box-sizing: border-box;
  @media screen and (max-width: 1080px) {
    width: 380px;
  }
  @media screen and (max-width: 769px) {
    width: 380px;
  }
  @media screen and (max-width: 420px) {
    width: 100%;
    border-radius: 0 0 24px 24px;
    padding: 0px 20px;
    &.login {
      padding: 40px 30px;
    }
  }
  /* @media only screen and (max-width: 600px) {
  body {\\\
    background-color: lightblue;

} */
`
const LoginIcon = styled.img`
  width: auto;
  /* margin-top: 30px; */
  /* margin-bottom: 20px; */
`
const TitleText = styled.h5`
  color: ${THEME_COLORS.text_title};
  font-style: normal;
  font-family: 'DM_sans_bold';
  /* font-size: 24px; */
  font-size: 1.5rem;
  white-space: nowrap;
  margin-top: 10px;
`
const SubTitleText = styled.span`
  color: ${THEME_COLORS.text_para};
  font-style: normal;
  font-weight: 400;
  /* font-size: 14px; */
  font-size: 0.875rem;
  white-space: nowrap;
  margin-top: 4px;
`
const LoginInputFieldContainer = styled.div`
  position: relative;
  background: ${THEME_COLORS.light_200};
  border-radius: 25px;
  width: 100%;
  box-sizing: border-box;
  padding: 6px 24px;
  border: 2px solid ${THEME_COLORS.light_200};
  height: 54px;
  box-sizing: border-box;
  &:focus-within {
    background: ${THEME_COLORS.white};
    border: 2px solid #000080;
    box-shadow: 0px 0px 0px 2px rgba(43, 57, 144, 0.2);
  }
  &:last-child {
    margin-bottom: 0;
  }
  &.error {
    border: 2px solid red;
    box-shadow: 0px 0px 0px 2px rgba(43, 57, 144, 0.2);
  }
`
const LoginInputLabel = styled.label`
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: ${THEME_COLORS.text_para};
`
const InputFieldContainer = styled.div`
  position: relative;
  background: ${THEME_COLORS.light_200};
  border-radius: 25px;
  width: 100%;
  padding: 15px 24px;
  height: 54px;
  border: 2px solid ${THEME_COLORS.light_200};
  /* height: 50px; */
  box-sizing: border-box;
  margin-top: 20px;
  &:focus-within {
    background: ${THEME_COLORS.white};
    border: 2px solid #000080;
    box-shadow: 0px 0px 0px 2px rgba(43, 57, 144, 0.2);
    /* padding-top: 24px; */
  }
  &.error {
    border: 2px solid red;
    box-shadow: 0px 0px 0px 2px rgba(43, 57, 144, 0.2);
  }
  &.active {
    background: ${THEME_COLORS.white};
    /* border: 2px solid #000080; */
    border: 2px solid #f4f4f4;
    /* box-shadow: 0px 0px 0px 2px rgba(43, 57, 144, 0.2); */
    /* padding-top: 24px; */
  }
  &.input-focused {
    border: 2px solid #000080;
  }
`
const GenderField = styled.div`
  color: ${THEME_COLORS.black};
  font-size: 14px;
  padding-top: 6px;
`
const Arrow = styled.img`
  width: auto;
  display: inline-block;
  padding-top: 6px;
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : null)};
  transition: 0.1s;
`

const InputLabel = styled.label`
  margin-bottom: 2px;
  display: block;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${THEME_COLORS.text_para};
  transition: all 0.3s ease;
  ${InputFieldContainer}:focus-within & {
    font-size: 12px;
    top: 0px;
  }
  &.active {
    font-size: 12px;
    top: 0px;
  }
`
const InputField = styled.input`
  display: block;
  width: 100%;
  font-size: 15px;
  font-size: 1rem;

  /* margin-top: 8px; */
`
const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  #email {
    width: calc(100% - 20px);
  }
`
const PreTexted = styled.span`
  display: block;
  margin-right: 6px;
  font-size: 16px;
`
const Alert = styled.img`
  display: none;
  height: 20px;
  width: 20px;
  position: absolute;
  right: 15px;
  top: 15px;
  &.show {
    display: block;
  }
`
const PreText = styled.span`
  margin-right: 6px;
  font-size: 16px;
`
const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 40px;
`
const BackButtonContainer = styled.div`
  display: flex;
  padding: 15px 24px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  border-radius: 32px;
  border: 2px solid #d3d3d3;
  background: #fff;
  width: 50%;
  height: 50px;
  cursor: pointer;
`
const ArrowImageContainer = styled.div`
  width: 10px;
  height: 15px;
`
const ArrowImage = styled.img``
const Previous = styled.span`
  font-size: 16px;
  font-family: 'DM_Sans';
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #333;
  text-align: center;
`

const LoginButton = styled.button`
  background: ${THEME_COLORS.secondary_gradient};
  border-radius: 32px;
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  font-family: 'DM_sans_medium';
  color: ${THEME_COLORS.white};
  /* font-size: 16px; */
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  &.otp {
    width: 50%;
    margin-top: 0;
    padding: 15px 24px;
  }
  &.signup {
    margin-top: 20px;
  }
`
const Caption = styled.div`
  padding: 0 10px;
  color: ${THEME_COLORS.text_para};
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
`
const Terms = styled(Link)`
  text-decoration: underline;
  color: ${THEME_COLORS.text_para};
  font-size: 14px;
  font-family: 'DM_sans_medium';
`
const Policy = styled(Link)`
  text-decoration: underline;
  color: ${THEME_COLORS.text_para};
  font-size: 14px;
  font-family: 'DM_sans_medium';
`
// const OrLine = styled.div`
//   width: 100%;
//   display: flex;
//   align-items: center;
//   margin-top: 12px;
//   /* justify-content: space-between; */
// `;
// const Hr = styled.hr`
//   width: 100%;
//   height: 1px;
//   background: ${THEME_COLORS.light_400};
//   margin: 0 8px;
//   /* border: 1px solid #d3d3d3; */
//   /* border: 1px solid #d3d3d3; */
// `;
// const OrText = styled.span`
//   display: block;
//   padding: 0 8px;
//   color: ${THEME_COLORS.text_title};
//   /* font-size: 16px; */
//   font-size: 1rem;
// `;
// const ContinueButton = styled.button`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 50px;
//   width: 100%;
//   box-sizing: border-box;
//   background: ${THEME_COLORS.white};
//   border: 1px solid ${THEME_COLORS.light_400};
//   border-radius: 52px;
//   cursor: pointer;
//   margin-top: 12px;
// `;
// const ContinueButtonIcon = styled.img`
//   width: 24px;
//   display: block;
//   margin-right: 16px;
// `;
// const ContinueButtonText = styled.h6`
//   /* font-size: 14px; */
//   font-size: 0.875rem;
//   font-family: "DM_sans_medium";
// `;
const SwitchLine = styled.div`
  /* display: flex;
    align-items: center; */
  display: inline-block;
  margin: 20px 0;
  text-align: center;
`
const GreyText = styled.span`
  color: ${THEME_COLORS.text_para};
  /* font-weight: 500; */
  /* font-size: 14px; */
  font-family: 'DM_sans_medium';
  font-size: 0.875rem;
`
const BlueText = styled.span`
  color: ${THEME_COLORS.chips_blue_on_container};
  /* font-weight: 600; */
  /* font-size: 14px; */
  font-size: 0.875rem;
  margin-left: 4px;
  font-family: 'DM_sans_medium';

  cursor: pointer;
`
const ExpertText = styled.h6`
  margin: 20px auto;
  text-align: center;
  font-weight: 500;
  /* font-size: 14px; */
  font-size: 0.875rem;
  a {
    color: ${THEME_COLORS.white};
    cursor: pointer;
  }
`
const RightArrow = styled.img`
  margin-left: 12px;
  width: auto;
`
const Header = styled.div`
  position: fixed;
  top: 0;
  display: none;
  height: 66px;
  width: 100%;
  background: ${THEME_COLORS.white};
  border-bottom: 1px solid ${THEME_COLORS.light_300};
  /* @media screen and (max-width: 420px) {
    display: flex;
    justify-content: center;
    align-items: center;
  } */
`
const HeaderLogo = styled.img`
  display: block;
  width: 180px !important;
`
const EmergencyButton = styled.div`
  padding: 10px;
  display: flex;
  border-radius: 50px;
  background: ${THEME_COLORS.white};
  cursor: pointer;
  position: absolute;
  right: 30px;
  bottom: 30px;
  z-index: 10;
`
const EmergencyIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`
const Emergency = styled.span`
  display: inline-block;
  font-size: 16px;
  font-family: 'DM_sans_medium';
  color: ${THEME_COLORS.chips_red_on_container};
`
const EmergencyContainer = styled.div``
const EmergencyTitle = styled.h2`
  font-family: 'DM_sans_medium';
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 10px;
  color: ${THEME_COLORS.text_title};
`
const Subtitle = styled.h4`
  font-size: 14px;
  font-family: 'DM_sans';
  margin-bottom: 20px;
  color: ${THEME_COLORS.text_title};
`
const SmallHead = styled.ul`
  font-family: 'DM_sans';
  font-size: 16px;
  margin-bottom: 16px;
  color: ${THEME_COLORS.text_title};
`
const Features = styled.li`
  font-family: 'DM_sans';
  font-size: 14px;
  color: ${THEME_COLORS.text_title};
  &:last-child {
    margin-bottom: 20px;
  }
`
const TagLine = styled.div`
  display: flex;
  gap: 10px;
  margin: 24px 0;
  background: ${THEME_COLORS.menu_blue};
  padding: 10px;
  border-radius: 8px;
  align-items: center;
`
const ImpContainer = styled.div`
  width: 48px;
`
const Imp = styled.img``
const Notify = styled.span`
  font-size: 14px;
  color: ${THEME_COLORS.dark_500};
`
const CallButton = styled.div`
  text-align: center;
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  background: ${THEME_COLORS.secondary_gradient};
  border-radius: 32px;
  color: ${THEME_COLORS.white};
  cursor: pointer;
`
const ContactImageContainer = styled.div`
  margin-top: 30px;
  width: 180px;
  margin-bottom: 23px;
`
const ContactImage = styled.img``
const ReachOut = styled.span`
  display: inline-block;
  font-family: 'DM_sans';
  font-size: 16px;
  margin-bottom: 24px;
  color: ${THEME_COLORS.text_title};
`
const GotButton = styled.div`
  text-align: center;
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  background: ${THEME_COLORS.secondary_gradient};
  border-radius: 32px;
  color: ${THEME_COLORS.white};
  cursor: pointer;
  margin-bottom: 30px;
`

const ErrorMessage = styled.span`
  font-size: 14px;
  color: #f04438;
  font-family: 'DM_sans';
  position: absolute;
  margin-left: 20px;
  margin-top: 2px;
  &.ageError {
    margin-left: 10px;
  }
  &.genderError {
    /* margin-left: 195px; */
    margin-left: 48%;
    @media screen and (max-width: 1080px) {
      margin-left: 47%;
    }
    @media screen and (max-width: 420px) {
      margin-left: 50%;
    }
    /* @media screen and (max-width: 1080px) {
            margin-left: 180px;
        }
        @media screen and (max-width: 769px) {
            margin-left: 178px;
        }
        @media screen and (max-width: 420px) {
            margin-left: 48%;
        } */
  }
  &.otpError {
    margin-top: 8px;
    margin-left: 10px;
  }
`
