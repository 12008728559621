import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

// Styles //
import styled, { keyframes } from "styled-components";

// colors //
import { THEME_COLORS } from "../../../ThemeConfig";

// Images & Icons
import Tick from "../../../assets/icons/success-tick.svg";
import calender from "../../../assets/icons/calender-green.svg";
import clock from "../../../assets/icons/clock-green.svg";
import profileIcon from "../../../assets/icons/profile-picture.svg";
import callIcon from "../../../assets/icons/session-call-icon.svg";
import video from "../../../assets/icons/video-green.svg";
import { Context } from "../../../contexts/Store";
import { PatientConfig } from "../../../axiosConfig";

function CreateSessionSuccessModal({
    data,
    setIsModalOpen,
    id,
    getHeaderNotifications,
}) {
    const { state } = useContext(Context);
    let accessToken = state.user_details.access_token;

    const handleSubmit = () => {
        const dataId = id;

        PatientConfig.post(
            "api/v1/general/notifications/",
            {
                mark_as_read_pks: [dataId],
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                },
            }
        )
            .then((response) => {
                setIsModalOpen(false);
                getHeaderNotifications();
            })
            .catch((error) => {
                // console.error("An error occurred:", error);
            });
    };

    return (
        <Wrapper>
            <Content onClick={(e) => e.stopPropagation()}>
                <SuccessIconContainer>
                    <SuccessIcon src={Tick} alt="Icon" />
                </SuccessIconContainer>
                <Title>Session accepted</Title>
                <Text>Your expert is ready to assist and support you</Text>
                <Text style={{ marginTop: "8px" }}>Case Id: {data?.id}</Text>

                <Container>
                    <ExpertDetails>
                        <ExpertImageContainer>
                            <ExpertImage
                                src={
                                    data?.expert_image
                                        ? data.expert_image
                                        : profileIcon
                                }
                                alt="Image"
                            />
                        </ExpertImageContainer>
                        <ExpertName>Dr {data?.expert_name}</ExpertName>
                        <ExpertSpecialization>
                            {data?.expert_specialization}
                        </ExpertSpecialization>
                    </ExpertDetails>
                    <SessionDetailsContainer>
                        <SessionDetails>
                            <IconContainer>
                                <Icon src={calender} alt="Icon" />
                            </IconContainer>
                            <Texts>{data?.date}</Texts>
                        </SessionDetails>
                        <SessionDetails>
                            <IconContainer>
                                <Icon src={clock} alt="Icon" />
                            </IconContainer>
                            <Texts>{data?.time}</Texts>
                        </SessionDetails>
                        <SessionDetails>
                            <IconContainer>
                                <Icon
                                    src={
                                        data?.type == "Video" ? video : callIcon
                                    }
                                    alt="Icon"
                                />
                            </IconContainer>
                            <Texts>{data?.type}</Texts>
                        </SessionDetails>
                    </SessionDetailsContainer>
                </Container>
                <Text>
                    Thank you for choosing our therapy services. We look forward
                    to helping you on your journey to well-being.
                </Text>
                <Button onClick={() => handleSubmit()}>Okay</Button>
            </Content>
        </Wrapper>
    );
}

export default CreateSessionSuccessModal;

const popup = keyframes`
    0%{
        scale: 0.3;
    }
    100%{
        scale: 1;
    }
`;
const Wrapper = styled.div`
    position: absolute;
    z-index: 350;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${THEME_COLORS.breath_model};
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: ${THEME_COLORS.white};
    width: 460px;
    max-height: 90vh;
    overflow-y: scroll;
    padding: 32px;
    z-index: 200;
    border-radius: 24px;
    animation: ${popup} 0.4s ease-in-out;
    @media all and (max-width: 780px) {
        padding: 20px 30px;
    }
    @media all and (max-width: 480px) {
        padding: 25px;
        width: 400px;
    }
    @media all and (max-width: 420px) {
        padding: 20px;
        width: 360px;
    }
    @media all and (max-width: 370px) {
        width: 330px;
        padding: 15px;
    }
`;
const SuccessIconContainer = styled.div`
    display: flex;
    width: 48px;
    height: 48px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    border: 8px solid #ecfdf3;
    background: #e0f8f2;
    margin-bottom: 10px;
`;
const SuccessIcon = styled.img``;
const Title = styled.h2`
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
    font-family: "DM_Sans";
    color: #3e3e53;
`;
const Text = styled.p`
    color: #818181;
    font-family: "DM_sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    @media all and (max-width: 370px) {
        font-size: 13px;
    }
`;
const Container = styled.div`
    display: flex;
    padding: 20px;
    align-items: flex-start;
    justify-content: space-between;
    align-self: stretch;
    border-radius: 8px;
    border: 1px dashed #e2e2e2;
    background: #fafafa;
    margin: 30px 0;
    @media all and (max-width: 780px) {
        margin: 15px 0;
    }
    @media all and (max-width: 480px) {
        padding: 15px;
    }
    @media all and (max-width: 370px) {
        padding: 12px;
    }
`;
const ExpertDetails = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    align-items: center;
    gap: 5px;
    border-radius: 8px;
    border: 1px solid #f4f4f4;
    background: #fff;
    width: 37%;
    @media all and (max-width: 480px) {
        padding: 13px;
    }
    @media all and (max-width: 370px) {
        padding: 12px;
    }
`;
const ExpertImageContainer = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 48px;
    margin-bottom: 5px;
`;
const ExpertImage = styled.img`
    width: 100%;
    height: 100%;
    display: block;
`;
const ExpertName = styled.h5`
    color: #1da687;
    font-family: "DM_Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    @media all and (max-width: 480px) {
        font-size: 15px;
    }
    @media all and (max-width: 370px) {
        font-size: 14px;
    }
`;
const ExpertSpecialization = styled.h6`
    color: #818181;
    font-family: "DM_Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
    @media all and (max-width: 370px) {
        font-size: 12px;
    }
`;
const SessionDetailsContainer = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 60%;
`;
const SessionDetails = styled.li`
    display: flex;
    padding: 8px 15px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #f4f4f4;
    background: #fff;
    @media all and (max-width: 480px) {
        padding: 8px 12px;
    }
    @media all and (max-width: 420px) {
        padding: 8px 8px;
        gap: 6px;
    }
`;
const IconContainer = styled.div`
    width: 18px;
    height: 18px;
`;
const Icon = styled.img``;
const Texts = styled.span`
    color: #686868;
    text-align: center;
    font-family: "DM_Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
    @media all and (max-width: 370px) {
        font-size: 12px;
    }
`;
const Button = styled.button`
    background: ${THEME_COLORS.secondary_gradient};
    color: ${THEME_COLORS.white};
    cursor: pointer;
    margin: 32px auto 0 auto;
    padding: 10px 0;
    box-sizing: border-box;
    display: block;
    font-size: 16px;
    border-radius: 20px;
    width: 100%;
    height: 40px;
    font-family: "DM_sans_medium";
    @media all and (max-width: 780px) {
        margin: 15px auto 0 auto;
    }
`;
