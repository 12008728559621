import axios from 'axios'

// demo
// export const PatientConfig = axios.create({
//   baseURL: ' https://api.mindmitra.talrop.works/',
// })
// export const socketUrl = 'wss://api.mindmitra.talrop.works'
// end of demo

// localhost
// export const PatientConfig = axios.create({
//     baseURL: "http://192.168.1.147:8000/", //Akbar's
//     // baseURL: "http://192.168.1.155:8000/", //Ruby's
// });
// export const socketUrl = "ws://192.168.1.147:8000"; //Akbar's
// export const socketUrl = "ws://192.168.1.155:8000"; //Ruby's
// end of localhost

// live
export const PatientConfig = axios.create({
  baseURL: 'https://api.mymindmitra.com/',
})
export const socketUrl = 'wss://api.mymindmitra.com'
// end of live
