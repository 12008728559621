import React from "react";
import { Link } from "react-router-dom";

// Styles //
import styled, { keyframes } from "styled-components";

// colors //
import { THEME_COLORS } from "../../../ThemeConfig";

const CancelModal = ({ closeModal, id }) => {
    return (
        <Wrapper onClick={closeModal}>
            <Content onClick={(e) => e.stopPropagation()}>
                <Title>Are you sure you want to Cancel?</Title>
                <Text>
                    Before you cancel, please note that only 80% of the current
                    plan is refundable.
                </Text>
                <RescheduleText>
                    <BlueText
                        to="mailto:patientcare@mymindmitra.com"
                        target="_blank"
                    >
                        patientcare@mymindmitra.com
                    </BlueText>{" "}
                    : You can use this email address to contact the admin to
                    reschedule the session
                </RescheduleText>
                <ButtonContainer>
                    <CancelButton onClick={closeModal}>Cancel</CancelButton>
                    <DoneButton to={`/cancel-session/${id}`}>
                        Continue
                    </DoneButton>
                </ButtonContainer>
            </Content>
        </Wrapper>
    );
};

export default CancelModal;

const popup = keyframes`
  0%{
      scale: 0.3;
  }
  100%{
      scale: 1;
  }
`;
const Wrapper = styled.div`
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: auto;
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 26px;
    background-color: ${THEME_COLORS.white};
    width: 31.3%;
    text-align: center;
    padding: 32px;
    z-index: 200;
    border-top: 4px solid ${THEME_COLORS.chips_green_on_container};
    box-shadow: 27px 27px 48px ${THEME_COLORS.elevation_user_u2};
    border-radius: 24px;
    animation: ${popup} 0.4s ease-in-out;

    @media all and (max-width: 1180px) {
        width: 40%;
    }
    @media all and (max-width: 1080px) {
        width: 42%;
    }
    @media all and (max-width: 980px) {
        width: 45%;
        padding: 28px;
    }
    @media all and (max-width: 880px) {
        width: 50%;
        padding: 27px;
    }
    @media all and (max-width: 780px) {
        width: 55%;
        padding: 26px;
    }
    @media all and (max-width: 680px) {
        width: 60%;
        padding: 25px;
    }
    @media all and (max-width: 680px) {
        width: 70%;
        padding: 25px;
    }
    @media all and (max-width: 480px) {
        padding: 24px;
        width: 85%;
    }
    @media all and (max-width: 420px) {
        padding: 22px;
        width: 90%;
    }
    @media all and (max-width: 370px) {
        width: 90%;
        padding: 20px;
    }
`;
const Title = styled.h3`
    color: #3e3e53;
    font-family: "DM_sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;
const Text = styled.span`
    color: #818181;
    text-align: center;
    font-family: "DM_sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;
const RescheduleText = styled.p`
    color: #818181;
    /* text-align: center; */
    text-align: center;
    font-family: "DM_sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* display: flex;
    align-items: center; */
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    align-self: stretch;
`;
const CancelButton = styled.button`
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex: 1 0 0;
    border-radius: 32px;
    border: 2px solid #d3d3d3;
    background: #fff;
    font-family: "DM_sans";
    cursor: pointer;
`;
const DoneButton = styled(Link)`
    cursor: pointer;
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex: 1 0 0;
    border-radius: 32px;
    background: linear-gradient(311deg, #2b3990 11.44%, #4f67f8 87.61%);
    color: #fff;
    font-family: "DM_sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;
const BlueText = styled(Link)`
    display: inline-block;
    color: #366ee3;
    font-size: 16px;
`;
