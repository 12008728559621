import React from "react";

// Styles //
import styled from "styled-components";

// colors
import { THEME_COLORS } from "../../../../ThemeConfig";

// Components //
import BottomNavigation from "../../../general/BottomNavigation";

function RefundPolicy() {
  return (
    <>
      <MainContainer>
        <Wrapper>
          <Terms>
            <>
              <Heading>Refund Policy</Heading>
              <Date>Last Updated December 15, 2023</Date>
              <ContentDiv>
                <Contents>
                  <SubContainer>
                    <Right>
                      <Para>
                        MindMitra offers it’s paid services under two
                        categories. <br /> &nbsp; 1. Single sessions <br />
                        &nbsp; 2. Packages with multiple sessions <br />
                        Refund policy is different for both cases and its given
                        below for your reference.
                      </Para>
                      <DescriptionHeading className="sub-heading">
                        For single sessions
                      </DescriptionHeading>
                      <Para className="heading">
                        Scenarios where the user will get a full refund
                      </Para>
                      <ListContainer>
                        <ListItem>
                          <Para>
                            If Mindmitra is unable to find a therapist within 4
                            working days for single session, we will let you
                            know the same and will initiate full refund after
                            you confirm. You may try to make a fresh booking for
                            another date. Mindmitra team will assist you to find
                            a session.
                          </Para>
                        </ListItem>
                      </ListContainer>
                      <Para className="heading">
                        Scenarios where the user will get a partial refund
                      </Para>
                      <ListContainer>
                        <ListItem>
                          <Para>
                            User has an option to cancel the booked session 48
                            hours prior to the session time. We suggest you to
                            be very sure about your need to undergo a therapy as
                            we will refund only the 50% of the original payment
                            upon cancellation. You may choose to reschedule your
                            session on unavoidable emergency, rather than
                            cancelling, by paying additional 20% of the original
                            payment fees. (Reschedule also need to be done
                            minimum 48 hours prior)
                          </Para>
                        </ListItem>
                      </ListContainer>
                      <Para className="heading">
                        Scenarios where the user will NOT get any refund
                      </Para>
                      <ListContainer>
                        <ListItem>
                          <Para>
                            If the user cancelled the session within 48 hours
                            prior to the session time
                          </Para>
                        </ListItem>
                        <ListItem>
                          <Para>
                            Mindmitra put lot of efforts in bringing in
                            qualified therapists to the platform. We won’t be
                            able to accept any request to change the therapist
                            once assigned and we won’t entertain a refund on
                            this matter.
                          </Para>
                        </ListItem>
                        <ListItem>
                          <Para>
                            User need to turn up on time for the session. Your
                            allotted time will start as per the schedule and if
                            you join late, you will be able to attend only the
                            remaining time as per your session plan. There won’t
                            be any refund or partial refund of any kind in this
                            scenario.
                          </Para>
                        </ListItem>
                        <ListItem>
                          <Para>
                            We are happy to hear your feedback about the
                            session. But we won’t be able to process any refund
                            for the session if you are not happy with session
                            experience. We will pass on the feedback to the
                            therapist and you may make fresh booking. We will
                            try our best to assign you another therapist.
                          </Para>
                        </ListItem>
                        <ListItem>
                          <Para>
                            If there is any connectivity or network issues from
                            the user’s side, we won’t able to issue any refund.
                            Please make sure you have access to internet with
                            proper network coverage before the session. If there
                            are any genuine issues on the platform used, we will
                            consider it case by case basis and reschedule the
                            session without any additional payment.
                          </Para>
                        </ListItem>
                        <ListItem>
                          <Para>
                            Therapist may choose to cancel the session on an
                            unavoidable situation. Mindmitra will try to find
                            another expert for the original session time. In
                            case of unavailability of any suitable expert for
                            the original session time, We will share the nearest
                            available slots to the user for selection. No refund
                            will be given on this scenario.
                          </Para>
                        </ListItem>
                        <ListItem>
                          <Para>
                            Therapist may choose to reschedule the session on an
                            unavoidable situation. User will be notified about
                            the new session time and the user can accept or
                            reject the new time slot. If the user chooses to
                            reject the new time slot, user will be given the
                            available other time slots of the same therapist.
                            He/she can choose from the options and confirm. No
                            refund will be given in this scenario.
                          </Para>
                        </ListItem>
                      </ListContainer>

                      <DescriptionHeading className="sub-heading">
                        For Packages
                      </DescriptionHeading>
                      <Para className="heading">
                        Scenarios where the user will get a full refund
                      </Para>
                      <ListContainer>
                        <ListItem>
                          <Para>
                            If Mindmitra is unable to find a therapist within 5
                            working days for package sessions, we will let you
                            know the same and will initiate full refund after
                            you confirm. You may try to make a fresh booking for
                            another date. Mindmitra team will assist you to find
                            a session.
                          </Para>
                        </ListItem>
                      </ListContainer>
                      <Para className="heading">
                        Scenarios where the user will get a partial refund
                      </Para>
                      <ListContainer>
                        <ListItem>
                          <Para>
                            There is no option to cancel individual session
                            under a package. If the user is not happy with the
                            therapy experience after few sessions, user can
                            cancel the package and we will refund you 50% of the
                            proportionate amount of the remaining sessions.
                          </Para>
                        </ListItem>
                      </ListContainer>
                      <Para className="heading">
                        Scenarios where the user will NOT get any refund
                      </Para>
                      <ListContainer>
                        <ListItem>
                          <Para>
                            User can’t cancel a package even before the first
                            session. Hence please be sure about your requirement
                            before making payment for a package session. There
                            won’t be any refund under any circumstances for this
                            scenario.
                          </Para>
                        </ListItem>
                        <ListItem>
                          <Para>
                            Mindmitra put lot of efforts in bringing in
                            qualified therapists to the platform. We won’t be
                            able to accept any request to change the therapist
                            once assigned (before starting the package or during
                            the package) and we won’t entertain a refund on this
                            matter. You may make fresh booking next time and we
                            will try our best to assign another therapist to
                            you.
                          </Para>
                        </ListItem>
                        <ListItem>
                          <Para>
                            User need to turn up on time for the session. Your
                            allotted time will start as per the schedule and if
                            you join late, you will be able to attend only the
                            remaining time as per your session plan. There won’t
                            be any refund or partial refund of any kind in this
                            scenario.
                          </Para>
                        </ListItem>
                        <ListItem>
                          <Para>
                            We are happy to hear your feedback about the
                            session. But we won’t be able to process any refund
                            for the sessions which got over in case you are not
                            happy with session experience. We will pass on the
                            feedback to the therapist.
                          </Para>
                        </ListItem>
                        <ListItem>
                          <Para>
                            If there is any connectivity or network issues from
                            the user’s side, we won’t able to issue any refund.
                            Please make sure you have access to internet with
                            proper network coverage before the session. If there
                            are any genuine issues on the platform used, we will
                            consider it case by case basis and reschedule the
                            session without any additional payment.
                          </Para>
                        </ListItem>
                        <ListItem>
                          <Para>
                            User may choose to reschedule your session on an
                            unavoidable emergency, by paying additional 20% of
                            the original payment fees at least 48 hours prior to
                            the session. There won’t be any refund if there is
                            no action from user and he/ she was absent for the
                            session.
                          </Para>
                        </ListItem>
                      </ListContainer>
                    </Right>
                  </SubContainer>
                </Contents>
              </ContentDiv>
            </>
          </Terms>
        </Wrapper>
      </MainContainer>

      <BottomNavigation />
    </>
  );
}

export default RefundPolicy;

const MainContainer = styled.div`
  width: 100%;
  background: ${THEME_COLORS.light_200};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 30px 0;
  @media all and (max-width: 768px) {
    width: 100%;
    padding-bottom: 60px;
  }
`;
const Wrapper = styled.div`
  width: 57%;
  display: flex;
  flex-direction: column;
  @media all and (max-width: 1350px) {
    width: 65%;
  }
  @media all and (max-width: 1250px) {
    width: 70%;
  }
  @media all and (max-width: 1150px) {
    width: 75%;
  }
  @media all and (max-width: 1050px) {
    width: 80%;
  }
  @media all and (max-width: 950px) {
    width: 85%;
  }
  @media all and (max-width: 900px) {
    width: 90%;
  }
  @media all and (max-width: 780px) {
    /* width: 80%; */
    padding-bottom: 50px;
  }
  @media all and (max-width: 1440px) {
    /* width: 80%; */
  }
  @media all and (max-width: 1440px) {
    /* width: 80%; */
  }
  @media all and (max-width: 1440px) {
    /* width: 80%; */
  }
`;
const Terms = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
`;
const Heading = styled.h3`
  font-size: 20px;
  text-align: start;
  font-family: "DM_sans_medium";
  color: ${THEME_COLORS.black};
  line-height: 26px;
  margin-bottom: 1px;
`;
const Date = styled.label`
  display: inline-block;
  font-size: 12px;
  text-align: start;
  font-family: "DM_sans";
  color: ${THEME_COLORS.text_label};
  margin-bottom: 44px;
`;
const ContentDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
`;
const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;
const SubContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;
const HeadingList = styled.span`
  color: #3e3e53;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;
const DescriptionList = styled.span`
  color: #3e3e53;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
`;
const SubHeading = styled.h5`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: ${THEME_COLORS.text_title};
  line-height: 20px;
`;
const Para = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${THEME_COLORS.text_para};
  &.heading {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  &.less-margin {
    margin-bottom: -8px;
  }
  &.left-blank {
    margin-top: -10px;
    margin-left: 10px;
  }
`;
const DescriptionHeading = styled.span`
  color: #3e3e53;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: -8px;
  &.sub-heading {
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    margin-top: 3px;
  }
`;
const ListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: ${THEME_COLORS.text_para};
  margin-top: -8px;
`;
const ListItem = styled.li`
  list-style: disc;
  list-style-position: outside;
  width: 97%;
`;
